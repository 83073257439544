.stepper {
  $circle-size: (
    small: 32px,
    medium: 72px
  );
  $stepper-color: theme-color('brand', 'medium2');
  $disabled-color: theme-color('base', 'silver');

  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 22px;

  .step {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;

    &.step-header {
      margin: 0;
      line-height: 44px;
      font-family: $nn-dagny-display;
      color: theme-color();
      font-size: 18px;

      @include breakpoint(medium) {
        display: none;
      }
    }

    .circle {
      $text-line-height: map-get($circle-size, small);
      display: block;
      width: map-get($circle-size, small);
      height: map-get($circle-size, small);
      border: 2px solid $stepper-color;
      border-radius: 50%;
      position: relative;
      font-family: $nn-dagny-text;
      font-size: 18px;
      text-align: center;
      line-height: 30px;
      color: $stepper-color;
      margin: 6px;

      @include breakpoint(medium) {
        width: map-get($circle-size, medium);
        height: map-get($circle-size, medium);
        line-height: 70px;
        margin: 0 130px 20px 0;
        font-size: 35px;
      }
    }

    &:not(:last-child) .circle:after {
      @include breakpoint(medium) {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        width: 80px;
        height: 20px;
        background: url('../images/arrow-solid.png') center right no-repeat;
      }
    }

    &.active ~ .step {
      * {
        color: $disabled-color;
        border-color: $disabled-color;
      }

      .circle:after {
        background-image: url('../images/arrow-dotted-grey.png');
      }
    }

    .title {
      display: none;
      max-width: 155px;
      font-family: $nn-dagny-display;
      font-size: 16px;
      color: $stepper-color;

      @include breakpoint(medium) {
        display: inline-block;
      }
    }
  }
}
