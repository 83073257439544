$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: '../fonts' !default;

$icon-customer: '\e900';
$icon-message: '\e901';
$icon-report: '\e902';
$icon-speech-buble: '\e903';
$icon-information: '\e906';
$icon-arrow-right: '\e904';
$icon-arrow-circle: '\e905';
$icon-search: '\e908';
$icon-twitter-circle: '\e909';
$icon-facebook-circle: '\e90a';
$icon-youtube-circle: '\e90b';
$icon-home: '\e907';
$icon-checkmark: '\e90c';
$icon-pdf: '\e90d';
$icon-star: '\e90e';
$icon-close: '\e90f';
$icon-arrow-left: '\e911';
$icon-arrow-up: '\e912';
$icon-arrow-down: '\e913';
$icon-document: '\e910';
