@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes expandRight {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes tooltipSlideUp {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 1rem);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes loader {
  0% {
    transform: scale(0);
    box-shadow: 0 0 0 0 rgba(238, 127, 0, .5), 0 0 0 0 rgba(238, 127, 0, .5);
  }

  16% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(238, 127, 0, .5), 0 0 0 0 rgba(238, 127, 0, .5);
  }

  32% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(238, 127, 0, .5), 0 0 0 0 rgba(238, 127, 0, .5);
  }

  48% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(238, 127, 0, .5), 0 0 0 20px rgba(238, 127, 0, .5);
  }

  64% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(238, 127, 0, .5), 0 0 0 0 rgba(238, 127, 0, .5);
  }

  82% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(238, 127, 0, .5), 0 0 0 0 rgba(238, 127, 0, .5);
  }

  100% {
    transform: scale(0);
    box-shadow: 0 0 0 0 rgba(238, 127, 0, .5), 0 0 0 0 rgba(238, 127, 0, .5);
  }
}

@keyframes reports-list-reveal {
  0% {
    opacity: 0;
    border-color: transparent;
    transform: translateX(2rem);
  }

  100% {
    opacity: 1;
    border-color: theme-color('base', 'border-color');
    transform: translateX(0);
  }
}

@keyframes expandLine {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}
