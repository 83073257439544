.row-equalize {
  & > .column,
  & > .columns {
    @include display-flex;
  }
}

.row-wrap {
  & > .column,
  & > .columns {
    @include flex-flow(row wrap);
  }
}

.relative {
  position: relative;
}
