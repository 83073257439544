.header {

  .row {
    padding: 1.25rem 0;

    .logo-img {

      @include breakpoint(large) {
        max-width: 13rem;
      }
    }

    .columns.text-right {
      @include flex-master(row, flex-end, center);
    }

    .btn {
      font-size:  .9125rem;
      background-color: theme-color('base', 'medium2');
      padding: .75rem .875rem;

      @include breakpoint(medium) {
        padding: .75rem 1.5rem;
      }
    }
  }
}
