.helpline {
  .header {
    font-size: 2.25rem;
    line-height: .82;
    padding: 2.46rem 0 1.92rem;
  }

  .helpline-phone {
    font-size: 1.75rem;
    color: theme-color('brand', 'medium2');
  }

  .helpline-text {
    padding-top: 1.5rem;
    .email {
      font-size: 1.37rem;
      color: theme-color('brand', 'medium2');
    }
  }
}
