$font-basepath: '../fonts';

@mixin font-face($name) {
  @font-face {
    font-family: $name;
    src: url('#{$font-basepath}/#{$name}.eot');
    src: url('#{$font-basepath}/#{$name}.eot?#iefix') format('embedded-opentype'),
    url('#{$font-basepath}/#{$name}.woff') format('woff'),
    url('#{$font-basepath}/#{$name}.ttf') format('truetype'),
    url('#{$font-basepath}/#{$name}.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
}

@include font-face($nn-dagny-text);
@include font-face($nn-dagny-text-light);
@include font-face($nn-dagny-display);
