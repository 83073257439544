.cookies {

  .section-header-wrapper {

    .row {
      text-align: left;
    }
  }

  .cookies-list {
    padding: 4rem 0;
    font-size: 1rem;
    line-height: 1.5;

    li {
      padding: 0 0 1rem .5rem;
    }

    .cookies-second-list {
      font-size: 1rem;
      line-height: 1.5;

      li {
        padding: 1rem 0 0 .5rem;
      }
    }
  }
}
