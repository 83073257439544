.contact {
  font-size: 1.376rem;
  line-height: 1.34;
  color: theme-color('base', 'charcoal');

  .contact-section {
    @include breakpoint(small only) {
      .columns {
        padding: 0;
      }
    }

    .contact-wrapper {
      padding-top: 5.46rem;
      padding-bottom: 0;
      @include flex-master(column, center, center);
      @include breakpoint(large) {
        @include flex-master(row, center, flex-start);
        padding-bottom: 18.615rem;
      }
    }

    .form-wrapper {
      @include breakpoint(small only) {
        padding: 0 1rem;
      }
      @include breakpoint(large) {
        width: 43.07rem;
      }
    }

    .form {
      padding-top: 2.15rem;
      .input-section {
        .inline-wrapper {
          font-size: 1rem;
          line-height: 1.37;
          font-family: $nn-dagny-display;
          .reference {
            font-family: $nn-dagny-text;
            padding-top: 1rem;
            font-size: 1.075rem;
          }

          .input {
            height: 44px;
          }
        }
        .contact-label {
          line-height: 1.8;
        }
        .validation-message {
          padding-top: .25rem;
          color: theme-color('brand', 'dark');
          font-family: $nn-dagny-text;
        }
        .form-textarea-single {
          height: 15.38rem;
        }

        .required-span {
          color: theme-color('brand', 'dark');
        }
      }
      .input-wrapper {
        margin-bottom: 2.46rem;
        input[type=checkbox]+label {
          color: theme-color('base', 'dove-grey');
          font-size: 1.123rem;
        }
      }
    }

    .background-wrapper {
      height: auto;
      padding: 2.3rem 1rem 5.38rem 2.3rem;
      background-color: theme-color('base', 'alabaster-grey');
      margin-top: 5.54rem;
      @include breakpoint(medium) {
        margin: 5.54rem 0;
        padding: 2.3rem 3.54rem;
      }
      @include breakpoint(large) {
        width: 27.538rem;
        min-height: 46.77rem;
        margin-left: 3.07rem;
        margin-top: 0;
      }
    }
  }
}
