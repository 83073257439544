@mixin display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin flex($value) {
  -webkit-flex: $value;
  -ms-flex: $value;
  flex: $value;
}
@mixin flex-flow($value) {
  -webkit-flex-flow: $value;
  -ms-flex-flow: $value;
  flex-flow: $value;
}
@mixin flex-direction($value) {
  -webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}
@mixin flex-wrap($value) {
  -webkit-flex-wrap: $value;
  flex-wrap: $value;
}
@mixin justify-content($value) {
  -webkit-justify-content: $value;
  justify-content: $value;
}
@mixin align-content($value) {
  -webkit-align-content: $value;
  align-content: $value;
}
@mixin align-self($value) {
  -webkit-align-self: $value;
  align-self: $value;
}
@mixin align-items($value) {
  -webkit-align-items: $value;
  align-items: $value;
}
@mixin flex-grow($value) {
  -webkit-flex-grow: $value;
  flex-grow: $value;
}
@mixin flex-basis($value) {
  -webkit-flex-basis: $value;
  flex-basis: $value;
}
@mixin flex-master($flex-flow: row wrap, $justify-content: flex-start, $align-items: center) {
  @include display-flex();
  @include flex-flow($flex-flow);
  @include justify-content($justify-content);
  @include align-items($align-items);
}
