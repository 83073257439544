%btn {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 1rem 1.25rem;
  text-align: center;
  font-family: $nn-dagny-display;
  font-size: 1.125rem;
  line-height: 1;
  overflow: hidden;
  border-radius: 3px;
  color: theme-color('base', 'white');
  border: none;
  transition: background-color .25s ease-in-out, color .25s ease-in-out;
  cursor: pointer;

  @include breakpoint(medium) {
    width: auto;
  };
}

.btn {
  @extend %btn;

  background-color: theme-color();

  &:hover,
  &:focus {
    background-color: darken(theme-color(), 10%);
    color: darken(theme-color('base', 'white'), 10%);
  }

  &.medium {
    font-size: 1.625rem;
    padding: 1.5rem 1.875rem;
  }

  &.large {
    font-size: 2.25rem;
    padding: 2rem 2.5rem;
  }

  &.mobile-small {
    @include breakpoint(medium down) {
      width: auto;
    }
  }

  &.bordered {
    border: 1px solid theme-color();
    color: theme-color();
    background: transparent;
  }
}

a,
.link {
  font-family: $nn-dagny-display;
  font-size: 1.125rem;
  text-decoration: none;
  color: theme-color('base', 'charcoal');
  cursor: pointer;

  &:hover,
  &:focus {
    color: theme-color();
  }

  &.arrowed {
    position: relative;
    padding-left: 1.25rem;

    &:before {
      content: $icon-arrow-right;
      @include icomoon();
      position: absolute;
      left: 0;
      top: 50%;
      color: theme-color();
      transition: left .5s ease-in-out;
      //
      line-height: 1;
      transform: translate(0, -50%);
    }

    &:hover,
    &:focus {
      &:before {
        left: .375rem;
      }
    }

    &.medium {
      padding-left: 1.625rem;
    }

    &.large {
      padding-left: 1.875rem;
    }

    &.return {
      color: theme-color('base', 'nobel');
      &:before {
        color: theme-color('base', 'nobel');
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }

  &.medium {
    font-size: 1.625rem;
  }

  &.large {
    font-size: 2.25rem;
  }

  &.inverted {
    color: theme-color();

    &:hover,
    &:focus {
      color: darken(theme-color(), 5%);
    }
  }
}

.more-btn {
  color: theme-color();
}

.attachment {
  display: inline-block;
  position: relative;
  padding-left: 2.5rem;
  line-height: 2rem;
  font-family: $nn-dagny-text;
  font-size: 1.125rem;

  [class^='icon-'],
  [class*=' icon-'] {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 2rem;
  }
}

a,
.link,
.btn {
  &.disabled {
    pointer-events: none;
  }
}
