.form {
  .form-image {
    display: none;
    @include breakpoint(large) {
      padding-top: 1.538rem;
      text-align: right;
      display: block;
    }
  }

  .section-header-wrapper {
    padding-top: 6.46rem;

    .row {
      padding: 0;
    }
  }

  .form-header {
    .show-for-small-only {
      padding-bottom: 0;
    }
    .stepper {
      margin-bottom: 0;
      display: flex;
      .step-header {
        flex-grow: 1;
      }
    }
  }

  main {
    padding-bottom: 160px;

    .field-row {
      margin-bottom: 0;
      @include breakpoint(large) {
        margin-bottom: 24px;
      }
      .label-wrapper {
        margin-top: 16px;
        height: 44px;
        display: flex;
        align-items: center;
        @include breakpoint(large) {
          margin-top: 0;
        }
      }
      .label {
        font-family: $nn-dagny-display;
        line-height: 1.25;
        br {
          display: none;
          @include breakpoint(large) {
            display: block;
          }
        }
      }
      .fieldset-radio {
        border-bottom: none;
        padding: 0;
        margin: 0;
        @include breakpoint(large) {
          margin: 0 0 14px;
        }
      }
      .hide-radio-star label:after {
        content: '';
        margin-left: 0;
      }
      &.required.hide-checkbox-star label:after {
        content: $icon-checkmark;
        margin-left: 0;
      }
    }
    .label-desc-format {
      font-family: $nn-dagny-text;
      color: theme-color('base', 'nobel');
    }

    .phone-input-wrapper {
      @include display-flex;
      @include align-items(center);
      .prefix {
        padding-right: .5rem;
      }
      .input-wrapper {
        width: 100%;
      }
    }
    .input-wrapper {
      margin-bottom: 0;
      &.radio {
        margin: auto 0;
      }
    }

    input {
      height: 44px;
    }
    .reference {
      margin: 1rem 0;
    }
    .validation-message {
      @include breakpoint(medium down) {
        padding-top: 5px;
      }
    }

    .radio-tooltip {
      @include breakpoint(small only) {
        margin-top: .2rem;
      }
    }

    .accordion-section {
      background: theme-color('base', 'white');

      &--title {
        font-family: $nn-dagny-display;
        font-size: 1.5rem;
        margin-bottom: 2rem;
        margin-top: 3.3rem;
        color: theme-color('base', 'black');
      }

      .accordion {
        .accordion-item {

          .item-title {
            position: relative;
            font-family: $nn-dagny-text;
            font-size: 1.125rem;
            margin: 0;
            padding: 1.75rem 2rem 1.75rem 0;
            color: theme-color('base', 'charcoal');
            cursor: pointer;
            border-top: 1px solid theme-color('base', 'border-color');
            transition: all .3s;
            line-height: 1.5;

            @include breakpoint(large) {
              padding: 1.125rem 2rem 1.125rem 0;
            }

            @include breakpoint(xxlarge) {
              padding: 1.75rem 2rem 1.75rem 0;
            }

            > [class^='icon-'],
            > [class*=' icon-'] {
              position: absolute;
              right: .375rem;
              top: 50%;
              transform: translateY(-50%);
              margin-right: .3125rem;
              color: theme-color('brand', 'medium');
              transition-duration: .5s;
              pointer-events: none;
            }


          }

          &:last-child {
            .item-title {
              border-bottom: 1px solid theme-color('base', 'border-color');
            }
          }

          .item-content {

            .content-wrapper {
              padding: 1.625rem 0 2.125rem;
              background: theme-color('base', 'white');

              @include breakpoint(large) {
                padding: 9rem 0 8.125rem 4rem;
              }
            }
          }

          &--text {
            position: relative;
            border-bottom: 1px solid lighten(theme-color('brand', 'light'), 35%);
            padding-bottom: 1rem;
            overflow-x: auto;
            margin-bottom: -1px;
            z-index: 9;
          }

          .attachment {
            display: block;
            position: relative;
            font-family: $nn-dagny-text;
            font-size: 1.125rem;
            margin: 0 0 .625rem;
          }

          &.expanded {
            background-color: theme-color('base', 'alabaster-grey');

            .item-title {
              padding: 1.75rem 2rem 1.75rem 0;
              border-top: 1px solid lighten(theme-color('brand', 'light'), 35%);
              margin: -1px;
              border-bottom: none;

              > [class^='icon-'],
              > [class*=' icon-'] {
                transform: translateY(-50%) rotate(180deg);
                transition-duration: .3s;
              }
            }

            .accordion-item--list {
              font-size: 1rem;
            }

            &:last-child {
              .item-title {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  .form-summary {
    border-bottom: 1px solid theme-color('base', 'silver');
    padding-bottom: 48px;
    margin-bottom: 47px;
    .form-summary-header {
      font-family: $nn-dagny-display;
    }
  }

  [disabled] {
    border: 1px solid theme-color('base', 'border-input');
    background-color: theme-color('base', 'snow');
    color: theme-color('base', 'nobel');
  }

  .nice-select.disabled {
    border: 1px solid theme-color('base', 'border-input');
    background-color: theme-color('base', 'snow');
    color: theme-color('base', 'nobel');
  }

  .contribution-by-category {

    &-container {
      padding: 1rem 0;
    }

    &-wrapper {
      padding: 1rem 0;
      border-top: 1px solid theme-color('base', 'border-color');
    }
  }

  .file-upload-button {
    background: none;
    border: none;
    outline: 0;
    padding: 0;
  }

  .file-upload-remove,
  .file-upload-filename,
  .filled .file-upload-button {
    display: none;
  }

  .filled .file-upload-remove,
  .filled .file-upload-filename {
    display: block;
  }

  .file-upload-remove,
  .file-upload-button {
    float: right;
  }

  .file-upload-filename {
    min-height: 2.125rem;
    padding: .375rem 0 .5rem 2.25rem;
    line-height: 1.625rem;
    background: url('../images/ico-doc.png') no-repeat 0 5px !important;
  }

  .file-upload-filename span {
    word-break: break-all;
    word-break: break-word;
  }

  .file-upload-filename em {
    font-style: normal;
    color: #999;
  }

  .filled + .file-upload-wrapper {
    margin-top: 1.75rem;
  }

  .filled + .filled {
    margin-top: 0;
  }

  .global-error .error,
  .file-upload-wrapper.error .help-block {
    display: block;
    padding: .25rem 0 0;
    margin-bottom: 1rem;
    font-size: .75rem;
    color: #e64415;
  }

  .circle.right:before,
  .circle.small.cross:before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-top: -1px;
    margin-right: 7px;
    border-radius: 13px;
    vertical-align: middle;
  }

  .circle.right:before {
    background: #f07200 url('../images/icons-small-white-horizontal.png') 0 -50px;
  }

  .circle.small.cross:before {
    background: #f07200 url('../images/icons-small-white.png') -350px -51px;
  }

  .btn {
    @include breakpoint(medium) {
      padding: 1rem 2.5rem;
    }
  }

  .validation-message-link {
    color: theme-color('brand', 'dark');
  }

  .alert-no-element {
    display: none;
    opacity: 0;
    visibility: hidden;

    @include breakpoint(large) {
      padding: 1rem 0 0;
    }

    &.active {
      display: block;
      opacity: 1;
      visibility: visible;
    }

    &-text {
      color: theme-color('brand', 'dark');
      transform: translateX(2rem);
      transition: all .5s ease-in-out;
    }
  }
}
