$theme-colors: (
  'base': (
    'white': #fff,
    'snow': #fcfcfc,
    'alabaster-grey': #f4f4f4,
    'white-smoke': #f8f8f8,
    'gallery-grey': #eee,
    'border-color': #d3d3d3,
    'border-input': #cac7c7,
    'silver': #c0c0c0,
    'nobel': #9b9b9b,
    'medium-grey': #8b8b8b,
    'dove-grey': #666,
    'charcoal': #414141,
    'black': #000
  ),
  'brand': (
    'light': #f39100,
    'medium': #ee7f00,
    'medium2': #ee7101,
    'dark': #e94d09
  ),
  'accent': (
    'green': #c8cd2e
  )
);
