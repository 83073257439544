.register {
  padding-bottom: 4.61rem;
  border-bottom: 1px solid theme-color('base', 'border-color');

  @include breakpoint(large) {
    overflow: hidden;
    border-bottom: none;
    padding-bottom: 0;
  }

  &-background {
    background: url('../images/register-background.jpg') top center no-repeat;
    background-size: cover;
    margin-bottom: 2.5rem;
    height: 23rem;
    width: 100%;

    @include breakpoint(small only) {
      max-height: 12rem;
    }

    @include breakpoint(medium) {
      min-height: 22rem;
    }

    @include breakpoint(large) {
      min-height: 32.3rem;
      margin-bottom: -32.3rem;
      background: url('../images/register-background-dark.jpg') top center no-repeat;
    }
  }

  &-wrapper {
    margin: 0 auto;

    @include breakpoint(large) {
      margin: 8rem 0;
    }
    @include breakpoint(xxlarge) {
      margin: 4rem 0;
    }
  }

  &-title {
    font-size: 2.25rem;
    color: theme-color('base', 'charcoal');
    font-family: $nn-dagny-display;
    line-height: 1.08;
    margin: 0 0 1.5rem;
    text-align: left;

    @include breakpoint(medium) {
      font-size: 3.125rem;
      text-align: center;
    }

    @include breakpoint(large) {
      color: theme-color('base', 'white');
      margin: 0 0 1rem;
    }
  }

  &-content {
    display: block;
    width: 100%;

    & &-text {
      margin: 0 0 2.5rem;
      color: theme-color('base', 'charcoal');
      line-height: 1.33;
      font-size: 1.125rem;
      text-align: left;

      @include breakpoint(medium) {
        text-align: center;
      }

      @include breakpoint(large) {
        padding: 0 1.25rem 2.25rem;
        margin: 1rem;
        color: theme-color('base', 'white');
        font-size: 1.4rem;
      }
    }

    .btn {
      margin: 0 auto;
      display: block;
      padding: 1rem 2.375rem;
      font-size: 1.375rem;

      @include breakpoint(large) {
        display: inline;
        padding: 1rem 3.5rem;
        width: auto;
      }
    }
  }
}

