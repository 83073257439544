.register-now {
  @include flex-master(column, center, center);
  background-color: theme-color('base', 'white');
  padding: 5rem 0;

  .columns {
    flex-basis: auto;
    @include flex-master(column, center, center);

    .section-header {
      padding: .5rem 0;
      text-align: center;
    }

    .section-description {
      width: 100%;
      font-size: 1.125rem;

      @include breakpoint(large) {
        width: 55%;
      }

      .description-text {
        text-align: center;
        line-height: 1.33;
      }
    }
  }

  .btn-register {
    padding: 1.125rem 3rem;
    margin-top: 1.25rem;
  }
}
