.registration-process {
  border-bottom: 1px solid theme-color('base', 'border-color');
  padding: 4.84rem 0;
  @include breakpoint(large) {
    padding: 5rem 0 8rem;
  }

  .section-header-wrapper {
    padding: 0 1.5rem 4.84rem;
    @include breakpoint(large) {
      padding: 0 1.5rem 2rem;
    }
  }

  .steps {
    background-image: none;
    @include breakpoint(large) {
      background-image: url('../images/arrow-dotted-big.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .row:first-of-type {
      padding-bottom: 0;
      @include breakpoint(large) {
        padding-top: 3.08rem;
        padding-bottom: 6rem;
      }
      @include breakpoint(xlarge) {
        padding-bottom: 7rem;
        padding-top: 3.615rem;
      }
    }

    .row:last-of-type {
      padding-bottom: 0;
      @include breakpoint(large) {
        padding-top: 3.461rem;
      }
    }

    .hidden-desktop {
      display: block;
      @include breakpoint(large) {
        display: none;
      }
    }
  }

  .arrow-wrapper {
    @include flex-master(row, center, center);
    height: 100%;
    @include breakpoint(large) {
      padding-bottom: 3.08rem;
    }
    @include breakpoint(xlarge) {
      padding-bottom: 3.076rem;
    }

    &.last {
      @include breakpoint(large) {
        padding-bottom: 4rem;
      }
      @include breakpoint(xlarge) {
        padding-bottom: 4.5rem;
      }
    }

    .arrow-down {
      display: block;
      padding-bottom: 2.5rem;
      @include breakpoint(large) {
        display: none;
      }
    }

    .arrow-right {
      display: none;
      @include breakpoint(large) {
        display: block;
        width: 80px;
        height: 20px;
      }

      .image {
        max-width: 150px;
        @include breakpoint(large) {
          max-width: 80px;
        }
      }
    }
  }

  .step {
    @include flex-master(column, center, flex-start);
    padding-bottom: 3rem;
    @include breakpoint(large) {
      @include flex-master(column, center, center);
      padding-bottom: 0;
    }

    .step-number {
      font-size: 1.25rem;
      margin-bottom: .4rem;
      color: theme-color('brand', 'medium');
    }

    .step-title {
      font-family: $nn-dagny-display;
      font-size: 1.7rem;
      text-align: left;

      @include breakpoint(medium) {
        text-align: center;
      }
    }

    .step-description {
      width: 90%;
      font-size: 1.125rem;
      @include breakpoint(large) {
        text-align: center;
      }
    }
  }
}
