@charset 'utf-8';

@import 'settings';
@import 'foundation';

@include foundation-global-styles;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-reveal;
//@include foundation-tooltip;

// @include foundation-grid;
// @include foundation-xy-grid-classes;
// @include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-top-bar;

// @include foundation-prototype-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;

$global-width-large: 86.125rem;
$global-width-xlarge: 93.5rem;

.row .row {
  margin: 0;
}

.row {
  @include breakpoint(large) {
    max-width: $global-width-large;
  }

  @include breakpoint(xlarge) {
    max-width: $global-width-xlarge;
  }

  @include breakpoint(xxlarge) {
    max-width: $global-width;
  }
}

.medium-shrink {
  @include breakpoint(medium) {
    flex: 0 0 auto;
    max-width: 100%;
  }
}

.medium-collapse {
  @include breakpoint(medium) {
    .column,
    .columns {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.reveal {
  .close-button {
    position: absolute;
    top: 0;
    right: 0;

    .icon-close {
      display: inline-block;
      padding: .5rem;
      font-size: 1.125rem;
      color: map-get($foundation-palette, primary);
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        transform: rotate(180deg);
      }
    }
  }
}

