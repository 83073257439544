.nn-tooltip-trigger {
  display: inline-block;
  font-size: 1.5rem;
  vertical-align: middle;
  color: theme-color('base', 'border-color');
  cursor: pointer;
  margin-left: .4rem;

  @include icomoon();
  position: relative;

  &:before {
    content: $icon-information;
  }

  &.no-icon {
    font-family: $nn-dagny-display !important;
    font-size: .9rem;
    color: theme-color();

    &:before {
      content: none;
    }

    &:hover,
    &:focus {
      color: darken(theme-color(), 5%);
    }
  }

  .nn-tooltip-content {
    display: none;
  }
}

.nn-tooltip-content {
  $tooltip-box-size: 10.125rem;
  $tooltip-scrollable-box-height: 15rem;
  $tip-size: .625rem;
  $box-shadow-color: rgba(theme-color('base', 'charcoal'), .1);

  display: block;
  position: absolute;
  left: 50%;
  width: $tooltip-box-size;
  padding: 2rem;
  background: theme-color('base', 'white');
  font-size: .875rem;
  font-family: $nn-dagny-text;
  line-height: 1.29;
  color: theme-color('base', 'charcoal');
  border: 1px solid theme-color('base', 'gallery-grey');
  box-shadow: 0 3px 10px $box-shadow-color;
  z-index: 1;

  body > & {
    animation: tooltipSlideUp .25s ease-in-out;

    &.active {
      transform: translate(-50%, 0);
    }
  }

  .icon-close {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    right: 0;
    position: absolute;
    top: 0;
    padding: 1.125rem 1.125rem 0 0;
    color: theme-color('base', 'charcoal');
    background-color: #fff;
    font-size: .625rem;

    &:hover {
      color: darken(theme-color('base', 'charcoal'), 50%);
    }
  }

  .tip {
    display: inline-block;
    border: solid $tip-size transparent;
    border-top-color: theme-color('base', 'white');
    position: absolute;
    bottom: -#{$tip-size * 2};
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.scrollable {
    width: 75vw;
    max-width: 35rem;

    .scroll-wrapper {
      display: block;
      max-height: $tooltip-scrollable-box-height;
      overflow: auto;
    }
  }
}
