.form-thank-you-page {
  .form-image {
    display: none;
    @include breakpoint(large) {
      text-align: right;
      display: block;
      padding-top: 1.538rem;
    }
  }

  .thank-you-content {
    font-size: 1.376rem;
    line-height: 1.846rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid theme-color('base', 'border-color');
  }

  .registration-process {
    padding: 4.92rem 0 0;
    border: none;
    @include breakpoint(large) {
      padding: 1rem 0 4.5rem;
    }
    @include breakpoint(xlarge) {
      padding: 2rem 0 4.5rem;
    }

    .steps {
      background-image: none;
      @include breakpoint(large) {
        background-image: url('../images/arrow-dotted-big.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .row:first-of-type {
        padding-bottom: 0;
        @include breakpoint(large) {
          padding-top: 5.08rem;
          padding-bottom: 6rem;
        }
        @include breakpoint(xlarge) {
          padding-bottom: 6.61rem;
          padding-top: 3rem;
        }
      }

      .row:last-of-type {
        padding-bottom: 0;
        @include breakpoint(large) {
          padding-top: 3.461rem;
        }
      }

      .hidden-desktop {
        display: block;
        @include breakpoint(large) {
          display: none;
        }
      }
    }

    .arrow-wrapper {
      @include flex-master(row, center, center);
      height: 100%;
      padding-bottom: 3rem;

      &.last {
        @include breakpoint(large) {
          padding-bottom: 5rem;
        }
        @include breakpoint(xlarge) {
          padding-bottom: 4rem;
        }
      }

      .arrow-down {
        display: block;
        padding-bottom: 1rem;
        @include breakpoint(large) {
          display: none;
        }
      }

      .arrow-right {
        display: none;
        @include breakpoint(large) {
          display: block;
          width: 80px;
          height: 20px;
        }

        .image {
          max-width: 150px;
          @include breakpoint(large) {
            max-width: 80px;
          }
        }
      }
    }

    .step {
      @include flex-master(column, center, flex-start);
      padding-bottom: 3rem;
      @include breakpoint(large) {
        @include flex-master(column, center, center);
        padding-bottom: 0;
        position: relative;
      }

      .step-grey {
        color: theme-color('base', 'nobel');
      }

      .icon-checkmark {
        color: theme-color('brand', 'medium');
        @include breakpoint(large) {
          position: absolute;
          top: -1rem;
        }
      }

      .step-number {
        font-size: 1.25rem;
        margin-bottom: .4rem;
        color: theme-color('brand', 'medium');
      }

      .step-title {
        font-family: $nn-dagny-display;
        font-size: 1.7rem;
      }

      .step-description {
        width: 90%;
        font-size: 1.125rem;
        @include breakpoint(large) {
          text-align: center;
        }
      }
    }
  }

  .exit-button {
    padding-top: 3.69rem;
    border-top: 1px solid theme-color('base', 'border-color');
    padding-bottom: 12.38rem;
  }
}
