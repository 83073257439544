.footer {
  background: theme-color('base', 'alabaster-grey');
  border-top: 1px solid theme-color('base', 'border-color');
  padding-top: 2.84rem;
  @include breakpoint(large) {
    padding-top: 0;
  }

  @media print {
    display: none;
  }

  .link-row {
    margin-bottom: 2.5rem;

    @include breakpoint(medium) {
      margin-bottom: 2.5rem;
    }

    @include breakpoint(large) {
      margin-bottom: 5.375rem;
    }
  }

  .additional-info {
    border-bottom: 1px solid theme-color('base', 'border-color');
    text-align: center;

    @include breakpoint(large) {
      text-align: start;
      padding: 2.25rem 0;
    }

    .legal-info {
      color: theme-color('base', 'nobel');
      font-size: .875rem;
      font-weight: 300;
      padding: .25rem 1.5rem 2.5rem;

      @include breakpoint(medium) {
        padding: .25rem 0 2rem 2.125rem;
      }

      @include breakpoint(large) {
        padding-bottom: 0;
        text-align: left;
      }

      p {
        line-height: 1.7;
        display: block;
        margin-bottom: 0;

        &:first-child {
          margin-right: .375rem;
        }

        @include breakpoint(medium) {
          display: inline-block;
        }

        @include breakpoint(large) {
          display: block;
        }
      }
    }

    .legal-links {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      list-style-type: none;
      padding-bottom: 2.5rem;
      margin-bottom: 0;
      margin-left: 0;
      white-space: nowrap;

      @include breakpoint(medium) {
        flex-direction: row;
        justify-content: center;
        padding-bottom: .625rem;
      }

      @include breakpoint(large) {
        padding-bottom: 0;
        margin-left: 0;
      }

      li {
        padding: .125rem .625rem 0 1rem;
        line-height: 1;
        text-align: center;

        @include breakpoint(medium) {
          padding: 0 .625rem;
        }

        @include breakpoint(large) {
          text-align: left;
          display: inline-block;
          line-height: 1.9;

          &:not(:last-child) {
            border-right: 1px solid theme-color('base', 'border-color');
          }
        }

        a {
          font-family: $nn-dagny-text;
          font-size: 1.125rem;
          line-height: 1.9;
          font-weight: 300;
          color: theme-color('base', 'nobel');

          @include breakpoint(large) {
            font-size: .875rem;
            display: inline-block;
            line-height: 1;
          }

          &:hover,
          &:focus {
            color: theme-color();
          }
        }
      }
    }
  }
}
