.security {

  .section-header-wrapper {

    .row {
      text-align: left;
    }
  }

  .accordion-section {
    background: theme-color('base', 'white');
    padding: 5.46rem 0 12.46rem;

    &--title {
      font-family: $nn-dagny-display;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      margin-top: 3.3rem;
      color: theme-color('base', 'black');
    }

    .accordion {
      .accordion-item {

        .item-title {
          position: relative;
          font-family: $nn-dagny-text;
          font-size: 1.125rem;
          margin: 0;
          padding: 1.75rem 2rem 1.75rem 0;
          color: theme-color('base', 'charcoal');
          cursor: pointer;
          border-top: 1px solid theme-color('base', 'border-color');
          transition: all .3s;

          @include breakpoint(large) {
            padding: 1.125rem 2rem 1.125rem 0;
          }

          @include breakpoint(xxlarge) {
            padding: 1.75rem 2rem 1.75rem 0;
          }

          > [class^='icon-'],
          > [class*=' icon-'] {
            position: absolute;
            right: .375rem;
            top: 50%;
            transform: translateY(-50%);
            margin-right: .3125rem;
            color: theme-color('brand', 'medium');
            transition-duration: .5s;
            pointer-events: none;
          }


        }

        &:last-child {
          .item-title {
            border-bottom: 1px solid theme-color('base', 'border-color');
          }
        }

        .item-content {

          .content-wrapper {
            padding: 1.625rem 0 2.125rem;
            background: theme-color('base', 'white');

            @include breakpoint(large) {
              padding: 9rem 0 8.125rem 4rem;
            }
          }
        }

        &--text {
          position: relative;
          border-bottom: 1px solid lighten(theme-color('brand', 'light'), 35%);
          padding-bottom: 1rem;
          overflow-x: auto;
          margin-bottom: -1px;
          z-index: 9;
        }

        .attachment {
          display: block;
          position: relative;
          font-family: $nn-dagny-text;
          font-size: 1.125rem;
          margin: 0 0 .625rem;
        }

        &.expanded {
          background-color: theme-color('base', 'alabaster-grey');

          .item-title {
            padding: 1.75rem 0;
            border-top: 1px solid lighten(theme-color('brand', 'light'), 35%);
            margin: -1px;
            border-bottom: none;

            > [class^='icon-'],
            > [class*=' icon-'] {
              transform: translateY(-50%) rotate(180deg);
              transition-duration: .3s;
            }
          }

          .accordion-item--list {
            font-size: 1rem;
          }

          &:last-child {
            .item-title {
              border-bottom: none;
            }
          }
        }
      }
    }

    .security-content {
      padding-bottom: 1rem;

      .security-content-list {
        font-size: 1rem;
        line-height: 1.5;
        @include breakpoint(medium down) {
          padding-left: 1.2rem;
        }


        li {
          padding: 1rem 0 0 .5rem;
        }
      }
    }
  }
}
