@import 'common/@common';
@import 'base/@base';

// Default variables
$font_size: 14px !default;
$font_size_small: 12px !default;

$input_border_radius: 2px !default;
$input_height: 3.125rem !default;
$input_height_small: 36px !default;

$gray_dark: #444 !default;
$gray: #999 !default;
$gray_light: #e8e8e8 !default;
$gray_lighter: theme-color('base', 'white-smoke') !default;
$primary_light: $gray !default;
$arrow_color: $gray !default;

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border: 1px solid theme-color('base', 'border-input');
  border-radius: $input_border_radius;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: $font_size;
  font-weight: normal;
  height: $input_height;
  line-height: $input_height - .125rem;
  outline: none;
  margin: 0;
  padding: 1rem 2.5rem 1rem 1rem;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  // Arrow
  &:after {
    content: $icon-arrow-down;
    @include icomoon();
    color: theme-color();
    font-size: 1.25rem;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center center;
    transition: transform .25s ease-in-out;
  }

  &.open {
    @extend :active;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &:after {
      transform: translateY(-50%) rotate(-180deg);
    }
    .list {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
    }
  }
  &.disabled {
    border-color: lighten($gray_light, 2%);
    color: $gray;
    pointer-events: none;
    &:after {
      border-color: lighten($arrow_color, 20%);
    }
  }

  // Modifiers
  &.wide {
    max-width: 100%;
  }

  &.small {
    font-size: $font_size_small;
    height: $input_height_small;
    line-height: $input_height_small - 2;
    &:after {
      height: 4px;
      width: 4px;
    }
    .option {
      line-height: $input_height_small - 2;
      min-height: $input_height_small - 2;
    }
  }

  .current {
    display: block;
    color: theme-color('base', 'medium-grey');;
    font-size: 1rem;
    line-height: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // List and options
  .list {
    background-color: #fff;
    border: 1px solid theme-color('base', 'border-input');
    border-top: 0;
    border-radius: $input_border_radius;
    box-sizing: border-box;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: calc(100% - 1px);
    left: -1px;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(- $input_height / 2);
    transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 9;
    width: calc(100% + 2px);
    max-width: none !important;
    max-height: calc(3.125rem * 5);
    overflow: auto;

    &:hover .option:not(:hover) {
      background-color: transparent !important;
    }

    .option {
      cursor: pointer;
      font-weight: 400;
      line-height: $input_height - 2;
      list-style: none;
      min-height: $input_height - 2;
      outline: none;
      padding: 1rem 2.5rem 1rem 1rem;
      text-align: left;
      font-size: 1rem;
      transition: all 0.2s;

      &:hover {
        background-color: $gray_lighter;
      }

      &.disabled {
        background-color: transparent;
        color: $gray;
        cursor: default;
      }

      &.selected {
        color: theme-color();
      }
    }
  }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
  .list {
    display: none;
  }
  &.open {
    .list {
      display: block;
    }
  }
}
