.section-header-wrapper {

  .row {
    text-align: center;
  }

  .section-header {
    font-family: $nn-dagny-display;
    font-size: 2.25rem;
    width: 100%;
    @include breakpoint(medium) {
      font-size: 3.125rem;
    }

    p {
      margin: 0;
    }
  }

  .section-description {
    width: 100%;
  }
}

body > .section-header-wrapper {
  background: theme-color('base', 'white');
}
