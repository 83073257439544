// sass-lint:disable no-css-comments
@import 'icons-variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hmuner');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hmuner#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?hmuner') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?hmuner') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?hmuner##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icomoon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*=' icon-'] {
  @include icomoon();
}

.icon-customer {
  &:before {
    content: $icon-customer;
  }
}
.icon-message {
  &:before {
    content: $icon-message;
  }
}
.icon-report {
  &:before {
    content: $icon-report;
  }
}
.icon-speech-buble {
  &:before {
    content: $icon-speech-buble;
  }
}
.icon-information {
  &:before {
    content: $icon-information;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-circle {
  &:before {
    content: $icon-arrow-circle;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-twitter-circle {
  &:before {
    content: $icon-twitter-circle;
  }
}
.icon-facebook-circle {
  &:before {
    content: $icon-facebook-circle;
  }
}
.icon-youtube-circle {
  &:before {
    content: $icon-youtube-circle;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark;
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-document {
  &:before {
    content: $icon-document;
  }
}
