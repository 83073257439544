.form-first-step {
  font-size: 1.376rem;
  line-height: 1.34;
  color: theme-color('base', 'charcoal');

  .form-first-step-section {

    .columns {
      margin: 0 auto;

      @include breakpoint(small) {
        padding: 0;
      }
    }

    .contact-wrapper {
      padding-top: 5.46rem;
      padding-bottom: 0;
      @include flex-master(column, center, center);

      @include breakpoint(large) {
        @include flex-master(row, center, flex-start);
        padding-bottom: 18.615rem;
      }
    }

    .first-step-wrapper {
      @include breakpoint(small) {
        padding: 0 1rem;
      }
      @include breakpoint(large) {
        width: 43.07rem;
      }

      .first-step-content {
        border-bottom: 1px solid theme-color('base', 'border-color');
        margin-bottom: 2rem;
        &-text {
          padding-bottom: 2rem;
          font-family: $nn-dagny-display;
          line-height: 1.84rem;
          font-size: 1.38rem;
        }
        &-list {
          font-size: 1.12rem;
          line-height: 1.53rem;
          padding-bottom: .61rem;
        }
        .content-list {
          margin-bottom: 3.69rem;
        }
        .list-content {
          padding-bottom: .61rem;
        }
      }
      .next-step-button {
        text-align: right;
      }
    }

    .background-wrapper {
      height: auto;
      padding: 2.3rem 1rem 5.38rem 2.3rem;
      background-color: theme-color('base', 'alabaster-grey');
      margin-top: 5.54rem;
      @include breakpoint(medium) {
        margin: 5.54rem 0;
        padding: 2.3rem 3.54rem;
      }
      @include breakpoint(large) {
        width: 27.538rem;
        min-height: 46.77rem;
        margin: 0 0 0 3.07rem;
      }
    }
  }
}
