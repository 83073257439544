.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: theme-color('base', 'black');
  font-family: $nn-dagny-text;
  line-height: 1;
}

.h1,
h1 {
  font-size: 2rem;
  font-family: $nn-dagny-text;
  @include breakpoint(large) {
    font-size: 2.375rem;
  }
}

.h2,
h2 {
  font-size: 1.25rem;
  @include breakpoint(medium) {
    font-size: 1.56rem;
  }
}

.h3,
h3 {
  font-size: 1rem;
}

.agreements {
  font-family: $nn-dagny-text;
  font-size: .9rem;
  color: theme-color('base', 'medium-grey');
}

.orange-light {
  color: theme-color('brand', 'light');
}

.orange-medium {
  color: theme-color();
}

.orange-medium-2 {
  color: theme-color('brand', 'medium2');
}

.orange-dark {
  color: theme-color('brand', 'dark');
}

.nn-dagny-text-light {
  font-family: $nn-dagny-text-light;
}

.nn-dagny-text {
  font-family: $nn-dagny-text;
}

.nn-dagny-display {
  font-family: $nn-dagny-display;
}
