.info {
  $info-border-color: theme-color('brand', 'medium2');
  @include flex-master(row, flex-start, center);
  margin-bottom: 48px;
  padding: 20px 13px;
  border-top: 1px solid $info-border-color;
  border-bottom: 1px solid $info-border-color;

  @include breakpoint(medium) {
    padding: 20px 50px;
  }

  &--description {
    font-size: .9125rem;
    margin: 0;
    text-align: left;
    line-height: 1.37;
  }

  &--icon {
    margin: 0 15px auto 0;
  }
}
