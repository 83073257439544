.password-reminder {
  font-size: 1.37rem;
  line-height: 1.84rem;

  .header {
    .align-center {
      padding: 0;
    }

    .login-image {
      display: none;
      @include breakpoint(large) {
        display: block;
        text-align: right;
        padding-top: 1.538rem;
      }
    }

    .logo-img {
      @include breakpoint(large) {
        max-width: 13rem;
      }
    }

    .section-header-wrapper {
      margin-top: 4.23rem;
    }
  }

  .password-reminder-section {
    padding-bottom: 3.76rem;
    @include breakpoint(medium) {
      padding-bottom: 13.5rem;
    }
    @include breakpoint(large) {
      padding-bottom: 18.5rem;
    }
  }

  .password-reminder-wrapper {
    .password-reminder-content {
      padding: 2.69rem 0 3.07rem;
      border-bottom: 1px solid theme-color('base', 'border-color');
      @include breakpoint(large) {
        padding: 3.07rem 0 2.61rem;
        margin-bottom: 3.07rem;
      }
    }

    .inputs-section {
      font-size: 1.12rem;
      border-bottom: 1px solid theme-color('base', 'border-color');
      padding-top: 3.38rem;
      @include breakpoint(large) {
        padding-top: 0;
      }

      .row {
        padding-bottom: 2.46rem;
      }

      .required {
        font-family: $nn-dagny-display;
      }

      .validation-message {
        font-family: $nn-dagny-text-light;
        padding-top: .25rem;
        color: theme-color('brand', 'dark');
      }

      .label {
        font-size: 1rem;
      }

      .input {
        height: 44px;
      }

      .required-span {
        color: theme-color('brand', 'medium2');
      }

      .reference {
        font-size: 1rem;
      }

      .phone-format {
        color: theme-color('base', 'nobel');
      }
    }

    .btn {
      margin: 2.76rem 0 0;
      @include breakpoint(large) {
        margin: 3.69rem 0 0;
      }
    }
  }

  .new-password-wrapper {
    .new-password-content {
      border-bottom: 1px solid theme-color('base', 'border-color');
      padding: 3.38rem 0 2.85rem;
    }

    .return-button {
      padding: 2.85rem 0;
      @include breakpoint(medium) {
        padding: 2.85rem 0 0;
      }
      @include breakpoint(large) {
        padding: 2.85rem 0 0;
      }
    }
  }
}
