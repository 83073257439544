.form-header {
  padding: 0 !important;
  @include breakpoint(large) {
    padding-bottom: 4.46rem;
  }

  .form-image {
    display: none;
    @include breakpoint(large) {
      padding-top: 1.538rem;
      text-align: right;
      display: block;
    }
  }
  .section-header-wrapper {
    .row {
      padding: 0;
    }
  }

  .go-home {
    .row {
      padding: 0;
    }
  }
}
