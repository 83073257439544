.login {
  .header {
    border-bottom: none;

    .align-center {
      padding: 0;
    }

    .login-image {
      display: none;
      @include breakpoint(large) {
        display: block;
        text-align: right;
        padding-top: 1.538rem;
      }
    }

    .logo-img {

      @include breakpoint(large) {
        max-width: 13rem;
      }
    }

    .section-header-wrapper {
      margin-top: 4.23rem;
    }
  }

  .login-section {
    padding: 2.15rem 0 6.64rem;
    @include breakpoint(medium) {
      padding-bottom: 14.38rem;
    }
    @include breakpoint(large) {
      padding-bottom: 17.38rem;
    }

    .form-login {

      .input-section {
        .inline-wrapper {
          font-size: 1.123rem;
          font-family: $nn-dagny-display;
          line-height: 1.8;
        }
        .label-row {
          display: flex;
          justify-content: space-between;
          .label-link {
            font-family: $nn-dagny-text;
            font-size: 1rem;
            color: theme-color('brand', 'dark');
          }
        }
      }

      .input {
        height: 44px;
      }

      .input-wrapper {
        margin-bottom: 1.846rem;
        margin-right: 0;
        .validation-message {
          padding-top: .25rem;
          color: theme-color('brand', 'dark');
          font-family: $nn-dagny-text-light;
        }
      }
    }
  }
}
