// Global

*:focus {
  outline: none;
}

html {
  @include breakpoint(large) {
    font-size: 13px;
  }

  @include breakpoint(xxlarge) {
    font-size: 16px;
  }
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  height: auto;
  min-height: 100%;
  background-color: theme-color('base', 'white');
  font-family: $nn-dagny-text, Calibri, Corbel, Verdana, sans-serif;
  color: theme-color('base', 'charcoal');
}

ul {
  font-family: $nn-dagny-text;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.25;
}

p {
  line-height: 1;

  &:not(:last-child) {
    margin: 0;
  }
}

hr {
  margin: 1.25rem 0 2.5rem;
}

// Inputs

$input-border-radius: 2px;

fieldset {
  border: none;
  padding: 0 0 40px;
  border-bottom: 1px solid theme-color('base', 'silver');
  margin-bottom: 50px;
}

legend {
  margin-bottom: .5rem;
  font-family: $nn-dagny-display;
}

button {
  cursor: pointer;
}

input {
  width: 100%;
  line-height: 44px;
  font-size: 1rem;
  padding: 0 16px;
  border: 1px solid theme-color('base', 'border-input');
  border-radius: $input-border-radius;

  &:focus {
    border-color: theme-color();
  }
}

.input-with-tooltip {
  display: block;
  background: theme-color('base', 'white');
  border-radius: $input-border-radius;
  border: 1px solid theme-color('base', 'border-input');
  height: 44px;
  overflow: hidden;

  @include breakpoint(large) {
    width: auto;
  }

  input {
    width: calc(100% - 46px);
    margin: 0;
    border: none;
    background: 0;
  }

  .nn-tooltip {
    padding: 0 5px;
  }
}

input[type='checkbox'] {
  $checkbox-margin: .25rem 0;
  $label-offset: (
    small: 1.6875rem,
    large: 2.1875rem
  );
  $checkbox-size: (
    small: 1rem,
    large: 1.5rem
  );

  display: none;

  & + label {
    font-size: .9rem;
    color: theme-color('base', 'medium-grey');
    display: inline-block;
    position: relative;
    padding: 0 0 0 map-get($label-offset, small);
    margin: $checkbox-margin;
    cursor: pointer;

    @include breakpoint(large) {
      padding-left: map-get($label-offset, large);
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: map-get($checkbox-size, small);
      height: map-get($checkbox-size, small);
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid theme-color('base', 'border-input');
      border-radius: $input-border-radius;
      cursor: pointer;

      @include breakpoint(large) {
        width: map-get($checkbox-size, large);
        height: map-get($checkbox-size, large);
      }
    }

    &:after {
      content: $icon-checkmark;
      @include icomoon();
      color: theme-color();
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: map-get($checkbox-size, small) / 2;
      line-height: map-get($checkbox-size, small);
      width: map-get($checkbox-size, small);
      text-align: center;
      opacity: 0;
      transition: opacity .25s ease-in-out;

      @include breakpoint(large) {
        font-size: map-get($checkbox-size, large) / 2;
        line-height: map-get($checkbox-size, large);
        width: map-get($checkbox-size, large);
      }
    }
  }

  &:checked + label:after {
    opacity: 1;
  }
}

input[type='radio'] {
  $label-offset: (
    small: 2.75rem,
    large: 2.1875rem
  );
  $radio-size: (
    small: 1.875rem,
    large: 1.5rem
  );

  display: none;

  & + label {
    display: inline-block;
    position: relative;
    padding: 0 0 0 map-get($label-offset, small);
    line-height: map-get($radio-size, small);
    margin-bottom: 0;
    font-size: .875rem;
    color: theme-color('base', 'medium-grey');
    cursor: pointer;

    @include breakpoint(large) {
      padding-left: map-get($label-offset, large);
      line-height: map-get($radio-size, large);
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: map-get($radio-size, small);
      height: map-get($radio-size, small);
      left: 0;
      top: 0;
      border: 1px solid theme-color('base', 'border-input');
      border-radius: 50%;
      cursor: pointer;

      @include breakpoint(large) {
        width: map-get($radio-size, large);
        height: map-get($radio-size, large);
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: map-get($radio-size, small);
      height: map-get($radio-size, small);
      border-radius: 50%;
      background: theme-color();
      transform: scale(0);
      transition: transform .25s ease-in-out;

      @include breakpoint(large) {
        width: map-get($radio-size, large);
        height: map-get($radio-size, large);
      }
    }
  }

  &:checked + label:after {
    transform: scale(.5);
  }
}

.datepicker {
  $datepicker-size: 3.125rem;

  width: $datepicker-size;
  height: $datepicker-size;
  margin-bottom: $datepicker-size / 4;
  border-radius: $input-border-radius;
  border: 1px solid theme-color('base', 'border-color');
  text-align: center;
  cursor: pointer;

  [class^='icon-'],
  [class*=' icon-'] {
    color: theme-color();
    font-size: $datepicker-size / 2;
    line-height: $datepicker-size;
  }
}

.number-input {
  $number-input-size: 3.125rem;
  $label-distance: 1rem;

  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-bottom: $number-input-size / 3;

  .label-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: $label-distance;

    .label {
      font-family: $nn-dagny-text;
      font-size: 1.125rem;
      margin: 0;
    }

    .additional-label {
      font-family: $nn-dagny-text-light;
      font-size: .875rem;
      margin: 0;
    }
  }

  .custom-input {
    display: inline-flex;
    vertical-align: top;
    border: 1px solid theme-color('base', 'border-color');

    button {
      outline: none;
      appearance: none;
      background-color: transparent;
      border: none;
      align-items: center;
      justify-content: center;
      width: $number-input-size;
      height: $number-input-size;
      cursor: pointer;
      margin: 0;
      position: relative;

      &:before,
      &:after {
        display: inline-block;
        position: absolute;
        content: '';
        width: 1rem;
        height: 3px;
        background-color: theme-color();
        transform: translate(-50%, -50%);
        border-radius: 3px;
      }

      &.increment:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    input[type='number'] {
      appearance: textfield;
      font-family: $nn-dagny-text;
      max-width: 3rem;
      padding: .5rem;
      border: none;
      font-size: 1.125rem;
      height: $number-input-size;
      text-align: center;
      margin: 0;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  &.min-limit {
    .decrement {
      &:before,
      &:after {
        background-color: theme-color('base', 'border-color');
      }
    }
  }

  &.max-limit {
    .increment {
      &:before,
      &:after {
        background-color: theme-color('base', 'border-color');
      }
    }
  }
}

textarea {
  height: 6rem;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  max-width: 100%;
  border: 1px solid theme-color('base', 'border-input');
  min-width: 16.5rem;
  border-radius: $input-border-radius;
  resize: vertical;
  width: 100%;
}

.input-wrapper {
  margin-bottom: 16px;

  @include breakpoint(large) {
    margin-bottom: 24px;
  }

  @include breakpoint(xxlarge) {
    margin-bottom: 32px;
  }

  &.checkbox,
  &.radio {
    margin-top: 6px;
    margin-bottom: 16px;

    @include breakpoint(large) {
      margin-top: 12px;
      margin-bottom: 24px;
    }

    @include breakpoint(xxlarge) {
      margin-top: 14px;
      margin-bottom: 39px;
    }
  }
}

form {
  legend {
    font-family: $nn-dagny-display;
    font-size: 23px;
    color: theme-color('brand', 'medium2');
    margin-bottom: 48px;
  }
  .disabled {
    color: theme-color('base', 'charcoal');
  }
}

.field-row {
  label,
  &.radio fieldset {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;

    legend {
      display: inline-block;
      font-family: $nn-dagny-display;
      color: theme-color('base', 'charcoal');
      padding: 10px 0;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  label,
  .validation-message {
    vertical-align: middle;
  }

  .validation-message {
    display: none;
    color: theme-color('brand', 'dark');
    opacity: 0;
    transform: translateX(2rem);
    visibility: hidden;
    transition: all .5s ease-in-out;
    padding: 10px 0 0;

    @include breakpoint(large) {
      padding: 10px 0 0 28px;
    }
  }

  &.required {
    label {
      position: relative;
      margin-right: 10px;

      &:after {
        content: '*';
        display: inline-block;
        line-height: 1;
        margin-left: .125rem;
        color: theme-color();
      }
    }
  }

  &.error {
    input,
    .input-with-tooltip {
      border-color: theme-color('brand', 'dark');
    }

    .validation-message {
      display: block;
      opacity: 1;
      transform: translateX(0);
      visibility: visible;
    }
  }
}

.grecaptcha-badge {
  display: none;
}

@media print {
  a[href]:after {
    content: none !important;
  }
}
