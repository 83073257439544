.nn-notify {
  background: theme-color('base', 'white');
  border-radius: 15px;
  padding: .5rem .375rem;
  line-height: .875rem;

  color: theme-color('accent', 'green');
  border: 1px solid theme-color('accent', 'green');

  left: 50%;
  transform: translateX(-50%);
  top: 5rem;
  display: none;
  position: fixed;
  font-size: 1rem;
  z-index: 1;
  text-align: center;

  &.error {
    color: theme-color('brand', 'dark');
    border: 1px solid theme-color('brand', 'dark');
  }
}

