.infobox-section {
  background-color: theme-color('base', 'white');
  padding: 6rem 0;
  border-bottom: 1px solid theme-color('base', 'border-color');
  @include breakpoint(large) {
    padding: 5rem 0 8rem;
  }
  .section-header-wrapper {
    padding: 0 1.5rem 4rem;
    @include breakpoint(large) {
      padding: 0 1.5rem 5rem;
    }
  }

  &--title {
    font-family: $nn-dagny-display;
    font-size: 3.125rem;
    margin-bottom: 3.125rem;
  }

  &-header {
    font-size: 1.375rem;
    line-height: 1.5rem;
    color: theme-color('brand', 'medium');
    margin: .9375rem 0;
    text-align: center;
    font-weight: 600;

    @include breakpoint(large) {
      margin: 0 0 .875rem;
    }

    @include breakpoint(xxlarge) {
      margin: .9375rem 0;
    }
  }

  &-text {
    padding: 0 1rem;

    @include breakpoint(medium down) {
      padding: 0 2.5rem;
    }

    @include breakpoint(small down) {
      padding-left: 3rem;
      text-align: left;
    }
  }

  &-box {

    @include breakpoint(small down) {
      display: flex;
    }
  }

  &-box-text {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-family: font-face($nn-dagny-display);

    p {
      line-height: 1.33;
    }

    @include breakpoint(small down) {
      float: left;
    }
  }

  &-image {
    height: 6.25rem;
    width: auto;
    margin: 1.375rem 0 !important;

    @include breakpoint(large) {
      margin: 0 0 1.25rem;
    }

    @include breakpoint(xxlarge) {
      margin: 0 0 1.375rem;
    }
  }

  &.__slim {

    .infobox-section--title {
      text-align: left;
      margin-bottom: 1.875rem;
    }

    .infobox-slim {
      display: block;
    }

    .infobox-section-box {
      display: flex;
      margin-bottom: 2rem;

      .row {
        min-width: 100%;
      }
    }

    .infobox-section-text {
      padding-left: 3.75rem;
      text-align: left;
    }

    .infobox-section-image {
      margin: 0 0 0 2.5rem  !important;
      height: auto;
      width: 5rem;
      float: left;
    }
  }
}
